<script lang="ts" setup>
const { open } = useCartModal()
const { count } = useCart()
</script>
<template>
  <HeaderActionButton class="cart hover" @click="open">
    <Icon name="cart" />
    <Badge v-if="count">{{ count }}</Badge>
  </HeaderActionButton>
  <ShopCartModal />
</template>
<style lang="scss" scoped>
.cart {
  position: relative;
}

.badge {
  position: absolute;
  bottom: 55%;
  left: 50%;
}
</style>
